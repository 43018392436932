// @flow
import * as React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";

import "./Menu.scss";
import { actions, selectors } from "store/reducers/builderReducer";

import TopSectionMenu from "./TopSectionMenu";
import MenuSearchProduct from "./MenuSearchProduct";
import MenuLayout from "./MenuLayout";
import Fade from "./Fade/Fade";
import * as helperFunctions from "utils/helper_functions";

import { cx, css } from "emotion";
import { Switch, Redirect, Route } from "react-router-dom";
import { MenuRootContext } from "./MenuRootContext";

const pageDucks = {
  intro: {
    contentClassName: "content-intro",
    topSection: {
      containerClassName: "intro-top-section",
    },
  },
};


class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productNameValue: "",
      inputSearchProductFocused: false,
      menuCustomStyle: "",
      fadeBackgroundStyle: "",
    };
  }
  componentDidMount = () => {
    if (this.props.preferenceUpdate > 0) {
      this.syncMenuWithPreferences();
    }
    this.addOrRemoveEventListenerLockScrol(1);
    this.props.setMenuCurrentPage({
      menuCurrentPage: selectors.getMenuCurrentPageSlug(
        this.props.history.location.pathname
      ),
    });
  };
  componentWillUnmount = () => {
    this.addOrRemoveEventListenerLockScrol();
  };
  addOrRemoveEventListenerLockScrol = (add) => {
    if (add) {
      window.addEventListener("touchstart", this.checkErrorBodyLocked);
    } else {
      window.removeEventListener("touchstart", this.checkErrorBodyLocked);
    }
  };
  checkErrorBodyLocked = (event) => {
    const menuContainerElement = document.getElementById(
      "menu-layout-container"
    );
    const bodyIsLocked = helperFunctions.menuBodyIsLocked();
    if (bodyIsLocked) {
      setTimeout(() => {
        //be aware event is not to use event in here
        const modalsElements = document.getElementsByClassName(
          "menu-modal-generic-container"
        );
        if (!modalsElements || modalsElements.length === 0) {
          helperFunctions.unLockMenuBodyContainer();
          this.closeAllOpenedModals();
        }
      }, 300);
    }
  };
  closeAllOpenedModals = () => {
    const { cartProductsOpen, productModalOpen } = this.props;
    if (cartProductsOpen) {
      //this.props.setCartProductOpen({cartProductsOpen: false, transitionTime: 0});
    }
    if (productModalOpen) {
      this.props.setMenuPageConfigs({
        menuPageConfigs: {
          ...this.props.menuPageConfigs,
          product_category: {
            ...this.props.menuPageConfigs.product_category,
            productModal: null,
            productModalTransitionTimeCustom: 0,
          },
        },
      });
    }
  };
  componentDidUpdate = (prevProps) => {
    if (
      this.props.preferenceUpdate &&
      prevProps.preferenceUpdate !== this.props.preferenceUpdate
    ) {
      this.syncMenuWithPreferences();
    }
    if (prevProps.menuCurrentPage !== this.props.menuCurrentPage) {
      this.setState({ productNameValue: "" });
      // if (prevProps.menuCurrentPage === "") {
      //   this.props.setLayoutLoader({
      //     layoutLoader: { open: false, duration: 400 },
      //   });
      // }
      // this.props.setLayoutLoader({
      //   layoutLoader: { open: false, duration: 400 },
      // });
    }
  };
  syncMenuWithPreferences = () => {
    const { preferences, menuProductCategories } = this.props;

    let menuPageConfigs = {
      intro: {
        page: "intro",
        backgroundImage: `https://api.poloniq.ro/uploads/digital_menu_assets/${
          preferences.id_venue
        }/${preferences.digital_menu_first_screen_bg_image}`,
        logo: `https://api.poloniq.ro/uploads/digital_menu_assets/${
          preferences.id_venue
        }/${preferences.digital_menu_first_screen_logo}`,
        title: preferences.digital_menu_welcome_text || "Bun venit la",
        backgroundImgOutside: true,
      },
      category: {
        page: "category",
        title: `Meniu ${preferences.name_venue}`
      },
      product_category: {
        page: "product_category",
        selectedCategory: {},
        title: "",
      },
      menuConfigGlobalUpdateKey: Date.now()
    };
    const {
      menu_color_body_bg,
      menu_color_bg_btn,
      menu_color_text_btn,
      menu_color_negative,
      menu_color_category_nr_txt,
      menu_color_bg_modal_product,
      menu_color_text_description,
      menu_color_modal_bg_ext,
      menu_color_secundary_negative,
      menu_color_cart_item_border,
      menu_color_product_item_bg,
      menu_color_product_weight_bg,
      menu_color_product_weight_txt,
      menu_color_cart_modal_bg,
      menu_color_product_modal_bg,
      menu_color_checkbox_fill,
      menu_color_product_item_bg_checked,
      menu_color_intro_top_bottom_fade,
      custom_font_title,
      custom_font_general,
    } = preferences;
    
    const menu_color_body_bg_LINE = `${
      menu_color_body_bg ? `background: ${menu_color_body_bg}!important;` : ""
    }`;
    const menu_color_bg_btn_LINE = `${
      menu_color_bg_btn
        ? `background-color: ${menu_color_bg_btn}!important;`
        : ""
    }`;
    const menu_color_bg_btn_AS_BORDER_LINE = `${
      menu_color_bg_btn ? `border-color: ${menu_color_bg_btn}!important;` : ""
    }`;
    const menu_color_bg_btn_AS_COLOR_LINE = `${
      menu_color_bg_btn ? `color: ${menu_color_bg_btn}!important;` : ""
    }`;
    const menu_color_text_btn_LINE = `${
      menu_color_text_btn ? `color: ${menu_color_text_btn}!important;` : ""
    }`;
    const menu_color_negative_LINE = `${
      menu_color_negative ? `color: ${menu_color_negative}!important;` : ""
    }`;
    const menu_color_category_nr_txt_LINE = `${
      menu_color_category_nr_txt
        ? `color: ${menu_color_category_nr_txt}!important;`
        : ""
    }`;
    const menu_color_bg_modal_product_LINE = `${
      menu_color_bg_modal_product
        ? `background-color: ${menu_color_bg_modal_product}!important;`
        : ""
    }`;
    const menu_color_text_description_LINE = `${
      menu_color_text_description
        ? `color: ${menu_color_text_description}!important;`
        : ""
    }`;
    const menu_color_modal_bg_ext_LINE = `${
      menu_color_modal_bg_ext
        ? `background-color: ${menu_color_modal_bg_ext}!important;`
        : ""
    }`;
    const menu_color_secundary_negative_LINE = `${
      menu_color_secundary_negative
        ? `color: ${menu_color_secundary_negative}!important;`
        : ""
    }`;
    const menu_color_cart_item_border_LINE = `${
      menu_color_cart_item_border
        ? `border-color: ${menu_color_cart_item_border} transparent!important;`
        : ""
    }`;
    const menu_color_cart_item_border_all_LINE = `${
      menu_color_cart_item_border
        ? `border-color: ${menu_color_cart_item_border}!important;`
        : ""
    }`;
    const menu_color_product_item_bg_LINE = `${
      menu_color_product_item_bg
        ? `background-color: ${menu_color_product_item_bg}!important;`
        : ""
    }`;
    const menu_color_product_item_bg_AS_COLOR_LINE = `${
      menu_color_product_item_bg
        ? `color: ${menu_color_product_item_bg}!important;`
        : ""
    }`;
    const menu_color_product_weight_bg_LINE = `${
      menu_color_product_weight_bg
        ? `background-color: ${menu_color_product_weight_bg}!important;`
        : ""
    }`;
    const menu_color_product_weight_bg_AS_BORDER_LINE = `${
      menu_color_product_weight_bg
        ? `border-color: ${menu_color_product_weight_bg}!important;`
        : ""
    }`;
    const menu_color_product_weight_txt_LINE = `${
      menu_color_product_weight_txt
        ? `color: ${menu_color_product_weight_txt}!important;`
        : ""
    }`;
    const menu_color_cart_modal_bg_LINE = `${
      menu_color_product_weight_txt
        ? `background-color: ${menu_color_product_weight_txt}!important;`
        : ""
    }`;
    const menu_color_product_modal_bg_LINE = `${
      menu_color_product_modal_bg
        ? `background-color: ${menu_color_product_modal_bg}!important;`
        : ""
    }`;
    const menu_color_product_item_bg_checked_LINE = `${
      menu_color_product_item_bg_checked
        ? `background-color: ${menu_color_product_item_bg_checked}!important;`
        : ""
    }`;
    const menuCustomStyle = `
      .menu-btn-container-item,
      .cart-products,
      .intro-btn {
        ${menu_color_bg_btn_LINE}
        .intro-btn-arrow, .intro-btn-text {
          ${menu_color_text_btn_LINE}
        }
      }
      .cart-products,
      .product-description-btn {
        ${menu_color_bg_btn_LINE}
        ${menu_color_text_btn_LINE}
      }
      .form.search-form {
        input::placeholder {
          ${menu_color_negative_LINE}
        }
        input {
          ${menu_color_negative_LINE}
        }
      }
      .product-description-title,
      .page-title,
      .arrow-back-container-top,
      .search-button i,
      .category-title,
      .product-name,
      .product-hands-on-description,
      .product-price,
      .product-description-title,
      .my-order-title,
      .inc-dec-btn-rel-cont-menu,
      .my-order-product-name div:first-child,
      .final-price,
      .my-order-close,
      .my-order-product-price,
      .actual-qty,
      .close-rel-mask-desk-menu,
      .intro-title,
      .select-arrow-down,
      .select-btn-container-item,
      .select-label-container,
      .select-btn-container-item .select-arrow-down .arrow-select
      {
        ${menu_color_negative_LINE}
      }
      .my-order-product {
        ${menu_color_cart_item_border_LINE}
      }
      .modal-open-animation-class {
        ${menu_color_modal_bg_ext_LINE}
      }
      .assoc-product-menu {
        ${menu_color_secundary_negative_LINE}
      }
      .select-btn-container-item {
        ${menu_color_cart_item_border_all_LINE}
      }
      .product-inner {
        ${menu_color_product_item_bg_LINE}
      }
      .product-add-btn {
        .plus-btn {
          ${menu_color_bg_btn_AS_COLOR_LINE}
        }
        .check-btn {
          ${menu_color_product_item_bg_AS_COLOR_LINE}
          ${menu_color_bg_btn_LINE}
        }
        ${menu_color_product_weight_bg_AS_BORDER_LINE}
      }
      .product-weight {
        > div {
          ${menu_color_product_weight_bg_LINE}
          ${menu_color_product_weight_txt_LINE}
        }
      }
      .form.search-form {
        input {
          ${menu_color_product_item_bg_LINE}
        }
        button {
          ${menu_color_product_item_bg_LINE}
        }
      }
      .category-number {
        ${menu_color_category_nr_txt_LINE}
      }
      .my-order {
        ${menu_color_cart_modal_bg_LINE}
      }
      .menu-modal-generic-container > div:first-child, 
      .product-desc-menu-contianer-btn {
        ${menu_color_product_modal_bg_LINE}
        .fade {
          box-shadow: ${"-50px 102px 119px " +
            (menu_color_product_modal_bg || "white")}!important;
        }
      }
      .product-description-price {
        ${menu_color_bg_btn_AS_COLOR_LINE};
      }
      .checkbox-menu-container .checkbox-menu-box {
        ${menu_color_bg_btn_AS_BORDER_LINE}
        path {
          ${
            menu_color_checkbox_fill
              ? `fill: ${menu_color_checkbox_fill || "black"}!important;`
              : ""
          }
        }
      }
      .product-description-text {
        ${menu_color_product_weight_txt_LINE};
      }
      .menu-product-checked {
        ${menu_color_product_item_bg_checked_LINE};
      }
      .top-section {
        ${menu_color_body_bg_LINE}
      }
      ${
        custom_font_title
          ? `.jost {
        font-family: ${custom_font_title}!important;
      }`
          : ""
      }
      ${
        custom_font_general
          ? `.poppins {
        font-family: ${custom_font_general}!important;
      }`
          : ""
      }
    `;
    this.setState({
      menuCustomStyle,
      fadeBackgroundStyle: css`
        box-shadow: ${"0 32vh 24vh " +
          (menu_color_intro_top_bottom_fade || "white")}!important;
      `,
    });
    this.props.setMenuPageConfigs({ menuPageConfigs });
    this.props.setLayoutLoader({
      layoutLoader: { open: false, duration: 400 },
    });
  };
  onChangeProductNameValue = (event) => {
    this.setState({ productNameValue: event.target.value });
  };
  onFocusSearchProductName = (event) => {
    this.setState({ inputSearchProductFocused: true });
  };
  onBlurSearchProductName = (event) => {
    this.setState({ inputSearchProductFocused: false });
  };
  render() {
    return (
      <React.Fragment>
        <MenuLayout
          menuCustomStyle={this.state.menuCustomStyle}
          productNameValue={this.state.productNameValue}
          inputSearchProductFocused={this.state.inputSearchProductFocused}
          parentValuePassedToContext={{
            state: this.state,
            history: this.props.history,
          }}
        >
          <MenuSearchProduct
            id_venue={
              this.props.preferences
                ? this.props.preferences.id_venue
                : "restaurant"
            }
            value={this.state.productNameValue}
            onChange={this.onChangeProductNameValue}
            onFocus={this.onFocusSearchProductName}
            onBlur={this.onBlurSearchProductName}
            placeholder={this.props.topSectionPlaceholder}
          />
        </MenuLayout>
        {this.props.menuCurrentPage === "intro" && (
          <Fade
            top={true}
            className={this.state.fadeBackgroundStyle}
            backgroundImageStyle={{
              backgroundImage: `url('${
                this.props.menuPageConfigs["intro"].backgroundImage
              }')`,
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  builder: { preferences, preferenceUpdate, menuConfig, pathname },
}) => {
  const productModalOpen = Boolean(
    objectPath.get(
      menuConfig,
      "menuPageConfigs.product_category.productModal.id_product"
    )
  );
  const cartProductsOpen = objectPath.get(menuConfig, "cartProductsOpen");

  return {
    preferences,
    preferenceUpdate,
    menuCurrentPage: objectPath.get(menuConfig, "menuCurrentPage"),
    productModalOpen,
    cartProductsOpen,
    menuPageConfigs: objectPath.get(menuConfig, "menuPageConfigs"),
    topSectionPlaceholder: objectPath.get(menuConfig, "menuPageConfigs.product_category.topSectionPlaceholder"),
  };
};
const mapDispatchToProps = (dispatch) => ({
  setLayoutLoader: (payload) => dispatch(actions.setLayoutLoader(payload)),
  setMenuPageConfigs: (payload) =>
    dispatch(actions.setMenuPageConfigs(payload)),
  setCartProductOpen: (payload) => {
    dispatch(actions.setCartProductsOpen(payload));
  },
  setMenuPageConfigs: (payload) => {
    dispatch(actions.setMenuPageConfigs(payload));
  },
  setMenuCurrentPage: (payload) =>
    dispatch(actions.setMenuCurrentPage(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
