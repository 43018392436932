import $ from "jquery";

/* <andrei> */
export function closeAllSelect(elmnt) {
  var x,
    y,
    i,
    arrNo = [];

  x = document.getElementsByClassName("select-items");

  y = document.getElementsByClassName("select-selected");

  for (i = 0; i < y.length; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i);
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }

  for (i = 0; i < x.length; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}
// change date of reservation if current day from date is blocked / venue is closed
export function startingDate(day_closed_array, day_blocked_array) {
  var today = new Date();
  var today_day = today.getDay();
  if (day_closed_array.length > 0 || day_blocked_array.length > 0) {
    var offset = 1;
  } else {
    var offset = 0;
  }
  for (let i = 0; i < day_closed_array.length; i++) {
    if (day_closed_array[i] === today_day + offset) {
      offset++;
    } else {
      for (let j = 0; j < day_blocked_array.length; j++) {
        var date_blocked = new Date(day_blocked_array[j]);
        var today_program = today.setDate(today.getDate() + offset);
        if (date_blocked == today_program) {
          offset++;
        }
      }
    }
  }

  today.setDate(today.getDate() + offset);
  return today;
}
// </andrei>

export function email_has_errors(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !(typeof email === "string" && email !== "" && re.test(email));
}
export function iOS() {
  var iDevices = [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ];

  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true;
      }
    }
  }
  return false;
}
export function is_object(obj) {
  return obj !== undefined && obj !== null && typeof obj === "object";
}
export function array_to_obj(arr, path = "id") {
  const obj = {};
  (Array.isArray(arr) ? arr : []).forEach((element) => {
    if (element[path]) {
      obj[element[path]] = element;
    }
  });
  return obj;
}
export function date_to_string(date = new Date(), longFormat) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}/${month < 10 ? "0" + month : month}/${
    day < 10 ? "0" + day : day
  }` + (() => {
    if(!longFormat) return "";
    return " " + date_to_time(date);
  })();
}
export function date_to_time(date = new Date()) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}`;
}
export function is_between_time_intervals(time_set, intervals) {
  const ref_txt = "1999/04/20 "; // yyyy/mmm/dd hhh mm ss how safari is like
  const time = new Date(ref_txt + time_set).getTime();
  for (let i = 0; i < intervals.length; i++) {
    const { start, end } = intervals[i];
    const time_start = new Date(ref_txt + start).getTime();
    const time_end = new Date(ref_txt + end).getTime();
    if (time >= time_start && time < time_end) {
      return true;
    }
  }
  return false;
}
export function compare_timesets(timeset1, timeset2, use_date) {
  const time_1 = new Date(
    use_date ? timeset1 : "1999/04/20 " + timeset1
  ).getTime();
  const time_2 = new Date(
    use_date ? timeset2 : "1999/04/20 " + timeset2
  ).getTime();
  if (time_1 === time_2) {
    return 0;
  }
  return time_1 > time_2 ? 1 : -1;
}
export function date_difference(date1, date2) {
  const time_1 = Math.floor(date1.getTime() / 1000);
  const time_2 = Math.floor(date2.getTime() / 1000);

  return time_1 - time_2;
}
export function compare_dates(date1, date2) {
  const time_1 = date1.getTime();
  const time_2 = date2.getTime();
  if (time_1 === time_2) {
    return 0;
  }
  return time_1 > time_2 ? 1 : -1;
}

export function compareShortDateString(dateString1, dateString2) {
  const time_1 = new Date(`${dateString1} 00:00:00`).getTime();
  const time_2 = new Date(`${dateString2} 00:00:00`).getTime();
  if (time_1 === time_2) {
    return 0;
  }
  return time_1 > time_2 ? 1 : -1;
}

export function compare_dates_string(dateString1, dateString2) {
  const time_1 = new Date(dateString1).getTime();
  const time_2 = new Date(dateString2).getTime();
  if (time_1 === time_2) {
    return 0;
  }
  return time_1 > time_2 ? 1 : -1;
}
export const find_error_for_inputs = {
  email: email_has_errors,
  name: (name) => {
    return !(typeof name === "string" && name.length > 0);
  },
  phone: (phone) => {
    const re = /^[0-9]*$/;
    return !(phone !== undefined && phone !== null && phone !== "");
    // && phone.length === 10
    //&& re.test(phone)
  },
};

export function clear_href_of_parameters(params = []) {
  const host = window.location.host;
  const protocol = window.location.protocol;
  const query_arr = window.location.search.split("?");
  const hash = window.location.hash;

  let query_new = "";
  if (query_arr.length > 1) {
    query_arr[1].split("&").forEach((item) => {
      if (item.indexOf("=") > -1) {
        const prop = item.split("=")[0];
        const val = item.split("=")[1];
        if (params.indexOf(prop) === -1) {
          query_new += (query_new === "" ? "?" : "&") + prop + "=" + val;
        }
      }
    });
  }
  return `${protocol}//${host}${query_new}${hash}`;
}
export function appendGetParamToLink(href = window.location, paramPairs = []) {
  const lastIndexOfQuestion = href.lastIndexOf("?");
  const lastIndexOfAnd = href.lastIndexOf("&");

  if (lastIndexOfQuestion === -1) {
    href += "?";
  } else {
    if (lastIndexOfAnd === href.length) {
      href = href.splice(0, href.length - 1);
    }
  }
  paramPairs.forEach(([property, value]) => {
    href += `&${property}=${value}`;
  });
  return href;
}
export function return_parameter(properties) {
  let string_url = window.location.href;
  string_url = string_url.replace("/#/", "/");
  let urlParams = new URL(string_url).searchParams;
  let values = {};
  properties.forEach((prop_name) => {
    values[prop_name] = urlParams.get(prop_name);
  });
  return values;
}
export function getUniqueSlugLocation() {
  const full = window.location.host.replace("www.", "");
  const sub = full.split(".")[0];
  let slug_call_link = "";
  if (full.indexOf("poloniq") === -1) {
    slug_call_link = `venue/${full}/custom_domain`;
  } else {
    slug_call_link = `venue/${sub}/unique_slug`;
  }
  /* 
  <--- AFTER BUILD ---> 
  1. fisier: index.html -> index.php
  2. index.html -> index.php continut in:
    => precache-manifest.js
    => service-worker.js
    => asset-manifest.js
  */
  if (window.dev === 1) {
    const { unique_slug, custom_domain } = return_parameter([
      "unique_slug",
      "custom_domain",
    ]);
    if (unique_slug) {
      if (custom_domain) {
        slug_call_link = `venue/${unique_slug.replace("www.", "")}/custom_domain`;
      } else {
        slug_call_link = `venue/${unique_slug}/unique_slug`;
      }
    }
  }
  return slug_call_link;
}
export function prepareStringForRawComparison(string) {
  const diacretics = {
    â: "a",
    ă: "a",
    î: "i",
    ș: "s",
    ț: "t",
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    ñ: "n",
    ü: "u",
  };
  return (string || "")
    .toLowerCase()
    .split("")
    .map((character) => diacretics[character] || character)
    .join("");
}

export function listenOutsideClick(event, id, exceptionSelector) {
  if (exceptionSelector) {
    if ($(event.target).closest(exceptionSelector).length > 0) {
      return false;
    }
  }
  if ($(id).length > 0) {
    if ($(event.target).closest(id).length > 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function get_info_parsed_l_st_venue(id_venue, property) {
  switch (property) {
    case "menu_command_cart": {
      const allMenuCommandCartItem = JSON.parse(
        localStorage.getItem("all_menu_command_cart_v2")
      );
      if (
        is_object(allMenuCommandCartItem) &&
        Array.isArray(allMenuCommandCartItem[id_venue])
      ) {
        return allMenuCommandCartItem[id_venue];
      }
      return null;
    }
    case "clients": {
      const allClients = JSON.parse(
        localStorage.getItem("clients_v2")
      );
      if (
        is_object(allClients) &&
        allClients[id_venue]
      ) {
        return allClients[id_venue];
      }
      return null;
    }
  }
  return null;
}
export function set_info_parsed_l_st_venue(id_venue, property, value) {
  switch (property) {
    case "menu_command_cart":
      {
        let allMenuCommandCartItem = JSON.parse(
          localStorage.getItem("all_menu_command_cart_v2")
        );
        if (is_object(allMenuCommandCartItem)) {
          allMenuCommandCartItem[id_venue] = value;
        } else {
          allMenuCommandCartItem = { [id_venue]: value };
        }
        localStorage.setItem(
          "all_menu_command_cart_v2",
          JSON.stringify(allMenuCommandCartItem)
        );
      }
      break;
    case "clients":
      {
        let allClients = JSON.parse(
          localStorage.getItem("clients_v2")
        );
        if (is_object(allClients)) {
          allClients[id_venue] = value;
        } else {
          allClients = { [id_venue]: value };
        }
        localStorage.setItem(
          "clients_v2",
          JSON.stringify(allClients)
        );
      }
      break;
  }
}

export function unLockMenuBodyContainer() {
  const menuContainerElement = document.getElementById("menu-layout-container");

  if(menuContainerElement) {
    menuContainerElement.overflowY = "";//scrolllock
    menuContainerElement.position = "";
    menuContainerElement.top = "";
    if(document.body.getAttribute("prevScrollTop") !== "none") {
      menuContainerElement.scrollTop = document.body.getAttribute("prevScrollTop") || 0; //###cat era inainte
      document.body.setAttribute("prevScrollTop", "none");
    }
  }
}

export function lockMenuBodyContainer() {
  const menuContainerElement = document.getElementById("menu-layout-container");
  let scrollTop = 0;
  if(menuContainerElement) {
    scrollTop = menuContainerElement.scrollTop;
    const scrollTopText = `${scrollTop}px`;

    menuContainerElement.position = "fixed";
    menuContainerElement.overflowY = "scroll";//scrolllock 
    menuContainerElement.top = scrollTopText;
    document.body.setAttribute("prevScrollTop", scrollTop);
  }
  return scrollTop;
}
export function menuBodyIsLocked() {
  const menuContainerElement = document.getElementById("menu-layout-container");
  return menuContainerElement && menuContainerElement.style.position === "fixed";
}